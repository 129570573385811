import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Box, Grid, Typography, Stack } from "@mui/material";

// components
import Link from "../utils/link";
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import Section from "../utils/section";

// markup
const PersonalizzaPage = () => {
    return (
        <Page
            title="Personalizza le tue salviette e i bavagli"
            description="In pochi e semplicissimi passi potrai avere anche tu la tua salvietta monouso o il tuo bavaglio in tnt con la tua grafica. Affidati al nostro team di esperti della personalizzazione per dare un tocco in più all'immagine del tuo locale."
            type="WebPage"
            readMore={["/contatti"]}
            image="/personalizza-prodotti.jpg"
        >
            <Hero
                title="Siamo esperti nella personalizzazione"
                description={
                    <>
                        Affidati al nostro team di esperti della personalizzazione per dare un tocco in più all'immagine del tuo locale.
                        <br />
                        Personalizzare i tuoi prodotti può sembrare un processo complicato ma non lo è affatto!
                    </>
                }
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Box pb={3}>
                                <StaticImage
                                    style={{ height: "100%" }}
                                    imgStyle={{ borderRadius: 16 }}
                                    src="../images/personalizzazione-salviette-monouso.jpg"
                                    alt="Salviette umidificate monouso come personalizzarle"
                                    layout="fullWidth"
                                />
                            </Box>
                            <Typography variant="h3" component="h2">
                                Salviette umidificate monouso: come personalizzarle?
                            </Typography>
                            <Typography>
                                In pochi e semplicissimi passi potrai avere anche tu la tua <strong>salvietta monouso personalizzata</strong>, ecco come fare:
                            </Typography>
                            <Stack component="ol" spacing={2}>
                                <li>
                                    scegli la tua <strong>fragranza</strong> preferita
                                    <br /> limone, lime e cannella, mojito, tè verde, coloniale, sweet fruit
                                </li>
                                <li>
                                    scegli il tipo di <strong>formato</strong>:<br /> standard (10cm x 6,6cm), formato maxi (14cm x 7,3cm)
                                </li>
                                <li>
                                    scegli il <strong>colore della carta</strong>: bianca, avana
                                </li>
                                <li>
                                    inviaci il <strong>tuo logo</strong> che verrà stampato nell'apposita area sull'involucro esterno
                                </li>
                            </Stack>
                            <Box pt={4}>
                                <Link type="internal" href="/contatti" title="Contatti">
                                    <Button size="large" variant="contained">
                                        contattaci
                                    </Button>
                                </Link>
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Box pb={3}>
                                <StaticImage
                                    style={{ height: "100%" }}
                                    imgStyle={{ borderRadius: 16 }}
                                    src="../images/personalizzazione-bavagli-monouso.jpg"
                                    alt="Bavagli in tnt monouso come personalizzarli"
                                    layout="fullWidth"
                                />
                            </Box>
                            <Typography variant="h3" component="h2">
                                Bavagli in tnt monouso: come personalizzarli?
                            </Typography>
                            <Typography>
                                In pochi e semplicissimi passi potrai avere anche tu i tuoi <strong>bavaglini monouso personalizzati</strong> in tnt, ecco come
                                fare:
                            </Typography>
                            <Stack component="ol" spacing={2}>
                                <li>
                                    scegli il tipo di <strong>formato</strong>:<br /> standard (h 44,5cm x 37cm), maxi (h 44cm x 40cm), bimbo (h 26cm x 24cm)
                                </li>
                                <li>
                                    inviaci il <strong>tuo logo</strong> che verrà stampato nell'apposita area sull'involucro esterno
                                </li>
                            </Stack>
                            <Box pt={4}>
                                <Link type="internal" href="/contatti" title="Contatti">
                                    <Button size="large" variant="contained">
                                        contattaci
                                    </Button>
                                </Link>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Section>
        </Page>
    );
};

export default PersonalizzaPage;
